<template>
	<div class="flexPage safe">
		<div class="flexHead">
			<navbar :params="pageInfo" />
		</div>
		<van-cell-group :border="false">
			<van-cell :title="$t('other.translate94')">
				<template #default>
					<span class="email">{{ !email ? $t('assets.safe.notBind') : email }}</span>
				</template>
			</van-cell>
			<!-- <van-cell :title="$t('other.translate95')" @click.native="$mts.goto('payment')" is-link :value="$t('other.translate96')" /> -->
			<van-cell :title="$t('form.password')" @click.native="$mts.goto('changePsw')" is-link
				:value="$t('assets.safe.modify')" />
			<van-cell :title="$t('form.assets.tradePsw')" @click.native="$mts.goto('changeAssetsPsw')" is-link
				:value="$t('assets.safe.modify')" />
		</van-cell-group>
	</div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";
export default {
	data() {
		return {
			pageInfo: {
				customTitle: this.$t("assets.personal.safe"),
				navbarStyle: "normal",
				backname: "my",
			},
		};
	},
	components: { navbar },
	computed: {
		email() {
			return JSON.parse(localStorage.getItem('user')).username;
		},
	},
};
</script>

<style>
.safe {
	height: 100vh;
	background: #F8FAFD;
}

.safe .email {
	color: #3861FB;
}

.safe .van-cell-group .van-cell:not(:last-child):after {
	left: 0;
}
</style>
